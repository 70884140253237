import React, { useMemo } from 'react'
import becomeAMemberBackground from 'assets/images/become-a-member-background.jpg'
import { useQuery } from 'urql'
import { graphql } from 'gql'
import BannerCardSection from './BannerCardSection'
import { ArrowRightIcon, DownloadAppStoreIcon, DownloadPlayStoreIcon } from 'components/icons'
import { useMobileApp } from 'data/hooks/MobileApp/useMobileApp'
import { useScreenWidth } from 'data/hooks/useScreenWidth'
import { config } from 'data/config'

const BECOME_A_MEMBER_QUERY = graphql(`
  query BecomeAMemberSection($download_mobile_app: Boolean) {
    cmsBecomeAMemberSection(download_mobile_app: $download_mobile_app) {
      subtitle
      heading
      content
      background_image
      buttons {
        ios
        android
      }
    }
  }
`)

const BecomeAMemberSection = () => {
  const { isMobile } = useScreenWidth()
  const { isMobileApp } = useMobileApp()

  const downloadMobileApp = useMemo(() => {
    return isMobile && !isMobileApp && Math.random() < 0.5
  }, [isMobile, isMobileApp])

  const [{ data, fetching }] = useQuery({
    query: BECOME_A_MEMBER_QUERY,
    variables: {
      download_mobile_app: downloadMobileApp,
    },
  })

  return (fetching || !data?.cmsBecomeAMemberSection)
    ? (
      <></>
    )
    : (
      <BannerCardSection
        className={{
          container: 'my-50 md:my-150',
        }}
        heading={data?.cmsBecomeAMemberSection?.heading}
        content={data?.cmsBecomeAMemberSection?.content}
        subtitle={data?.cmsBecomeAMemberSection?.subtitle}
        includePadding={false}
        bannerImage={data?.cmsBecomeAMemberSection?.background_image ?? becomeAMemberBackground}
        altText="Banner background"
        buttons={data?.cmsBecomeAMemberSection?.buttons
          ? [
            {
              href: config.mobileStore.ios,
              text: data?.cmsBecomeAMemberSection?.buttons?.ios ?? '',
              icon: <DownloadAppStoreIcon />,
              size: 'sm',
            },
            {
              href: config.mobileStore.android,
              text: data?.cmsBecomeAMemberSection?.buttons?.android ?? '',
              icon: <DownloadPlayStoreIcon />,
              size: 'sm',
            },
          ]
          : [
            {
              href: '/membership',
              text: 'Read More',
              icon: <ArrowRightIcon />,
            },
          ]
        }
      />
    )
}

export default BecomeAMemberSection
